import React, { useState, useMemo } from "react";
import { connect } from "react-redux";
import AnimateOnChange from "react-animate-on-change";
import "./addedRowsAnimations.scss";
import { isInternalWS } from "../../../../appRedux/actions/helpers";

function CssPop({ comparator, ticker, currentPrices, dollar = true }) {
  // Use memoization to compute the current price efficiently
  const currentPrice = useMemo(() => {
    const price = currentPrices.get(ticker) || comparator;
    return typeof price === "string" ? parseFloat(price) : price;
  }, [currentPrices, ticker, comparator]);

  const [price, setPrice] = useState(currentPrice);

  // Only trigger a state update if the current price changes
  const handleAnimationEnd = () => {
    if (price !== currentPrice) {
      setPrice(currentPrice);
    }
  };

  // Compute animation class based on the price comparison
  const animationClassName = useMemo(() => {
    return `new-item-options${currentPrice > price ? "-up" : "-down"}`;
  }, [currentPrice, price]);

  return (
    <AnimateOnChange
      baseClassName="nuetral"
      animationClassName={animationClassName}
      onAnimationEnd={handleAnimationEnd}
      animate={currentPrice !== price}
    >
      {`${dollar ? "$" : ""}${Number(price).toFixed(2)}`}
    </AnimateOnChange>
  );
}

const stateToProps = (state) => ({
  currentPrices: isInternalWS
    ? state.EquitiesSocket.currentPrices
    : state.dxFeedSocket.socketData,
});

export default connect(stateToProps, null)(CssPop);
