import InfiniteScroll from "react-infinite-scroll-component";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import moment from "moment";
import { connect } from "react-redux";
import { socketListen } from "../../appRedux/ducks/socket";
import { Helmet } from "react-helmet";
import Summary from "./Summary";
import "./index.scss";
import { RangePicker } from "../Earnings/components/calendar.tsx";
import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { searchLoad } from "../../appRedux/ducks/search";
import OptionTickerSearch from "../OptionScanner/OptionTickerSearch";
import useDebounce from "../OptionScanner/useDebounce";
import { Link } from "react-router-dom";
import "../common/Tables/liveOptionsTable.css";
import "../common/Tables/animations/addedRowsAnimations.scss";
import TradeAlgoTable from "../common/Tables/TradeAlgoTable";
import { symbolSetter } from "../../appRedux/ducks/symbol";
import { liveOptionsLoad } from "../../appRedux/ducks/newLiveOptions";
import { abbreviate } from "../homePage/TradeAlgoCard";
import { useMedia } from "react-media";
import clsx from "clsx";
import { useHistory, useParams } from "react-router-dom";
import BidAskSelect from "./BidAskSelect";
import usePrevious from "../../hooks/usePrevious";
import BBi from "../../assets/images/liveOptions/BBi.png";
import AAi from "../../assets/images/liveOptions/AAi.png";
import LAi from "../../assets/images/liveOptions/LAi.png";
import LBi from "../../assets/images/liveOptions/LBi.png";
import { TableSkeleton } from "../common/Skeletons/TableSkeleton";
import CustomSlider from "./CustomSlider.js";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.primary.tableBackground + " !important",
  },
  LA: {
    backgroundColor: "rgba(220, 0, 78, 0.8)",
  },
  LB: {
    backgroundColor: "#EA580C",
  },
  BB: {
    backgroundColor: "#2563EB",
  },
  AA: {
    backgroundColor: " #0D9488",
  },
  LAi: {
    color: "rgba(220, 0, 78, 0.8)",
  },
  LBi: {
    color: "#EA580C",
  },
  BBi: {
    color: "#2563EB",
  },
  AAi: {
    color: " #0D9488",
  },
  text: {
    color: theme.palette.primary.text + " !important",
  },
  popper: {
    "& .MuiSelectUnstyled-root": {
      background: theme.palette.primary.select,
      color: theme.palette.primary.text,
    },
    "& .MuiSelectUnstyled-listbox ": {
      fontFamily: "IBM Plex Sans,sans-serif",
      fontSize: "14px",
      boxSizing: "borderBox",
      padding: "5px",
      margin: "0px 0",
      //minWidth: "256px",
      background: "#F1F5F9",
      borderRadius: "24px",
      color: "black",
      overflow: "auto",
      outline: "0px",
    },
  },
}));

const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
  isResponsiveHeaderCell: "screen and (min-width: 1800px)",
};

const valuetext = (value) => {
  return <span className="slider-tooltip"> {abbreviate(value, 2)} </span>;
};
const valuetextExpire = (value) => {
  return (
    <span className="slider-tooltip">
      {" "}
      {`${value} Day${value === 1 ? "" : "s"}`}{" "}
    </span>
  );
};

const today = new Date();

const LiveOptionsContainer = ({
  themeMode,
  listen,
  searchedSymbols,
  loading,
  searchAllSymbols,
  company = false,
  symbols,
  setSymbolRedux,
  liveFetchOptions,
  newLiveOptions,
  optionsLoading,
  excludeSymbols,
  firstRender,
  defaultTickersSettings,
}) => {
  const { isMobile, isResponsiveHeaderCell } = useMedia({
    queries: mediaQuery,
  });
  const classes = useStyles();
  const [bidAsk, setBidAsk] = useState([]);
  // const search = useLocation().search;
  const params = useParams();
  const all = params["0"]?.split("/") || {};
  const allParams = useMemo(() => {
    // live-options/prem/minExpiry,maxExpiry/minContract/tickers/dateRange
    if (!company) {
      if (all[4]) {
        let [prem, expiryRange, minContract, tickers, dateRange] = all;
        let [minExpiry, maxExpiry] = expiryRange.split(",").map(Number);
        return {
          prem,
          minExpiry,
          maxExpiry,
          minContract,
          tickers,
          dateRange,
        };
      } else if (all[3]) {
        let [prem, expiryRange, minContract, tickers] = all;
        let [minExpiry, maxExpiry] = expiryRange.split(",").map(Number);
        return {
          prem,
          minExpiry,
          maxExpiry,
          minContract,
          tickers,
        };
      } else if (all[2]) {
        let [prem, expiryRange, minContract] = all;
        let [minExpiry, maxExpiry] = expiryRange.split(",").map(Number);
        return {
          prem,
          minExpiry,
          maxExpiry,
          minContract,
        };
      } else {
        let [prem, expiryRange] = all;
        let [minExpiry, maxExpiry] = expiryRange.split(",").map(Number);
        return {
          prem,
          minExpiry,
          maxExpiry,
        };
      }
    }
  }, [all, company]);

  const history = useHistory();
  const [minPremium, setMinPremium] = useState(
    allParams?.prem ? Number(allParams?.prem) : 35000,
  );
  const [contractMin, setContractMin] = useState(
    allParams?.minContract ? Number(allParams?.minContract) : 0,
  );
  const [expiryRange, setExpiryRange] = useState(
    !!allParams?.minExpiry && !!allParams?.maxExpiry
      ? [Number(allParams.minExpiry), Number(allParams.maxExpiry)]
      : [0, 730],
  );
  const debouncedContractMin = useDebounce(contractMin, 400);
  const debouncedExpire = useDebounce(expiryRange, 400);
  const debouncedPrem = useDebounce(minPremium, 400);
  const [show, setShow] = useState(false);
  const [rangeString, setRangeString] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: today,
    endDate: today,
    key: "selection",
  });

  const dateRangeString = useMemo(() => {
    const todayMoment = moment().format("YYYY-MM-DD");
    if (
      moment(dateRange.startDate).format("YYYY-MM-DD") === todayMoment &&
      moment(dateRange.endDate).format("YYYY-MM-DD") === todayMoment
    ) {
      return "Today";
    } else {
      setBidAsk([]);
      return `${moment(dateRange.startDate).format("DD MMM, YYYY")} - ${moment(
        dateRange.endDate,
      ).format("DD MMM, YYYY")}`;
    }
  }, [dateRange]);
  const prevRange = usePrevious(dateRangeString);
  const prevBidAsk = usePrevious(bidAsk);
  const [symbol, setSymbol] = useState("");
  const [tickerString, setTickerString] = useState(
    allParams?.tickers ? allParams?.tickers : "",
  );
  const [ticker, setTicker] = useState(
    allParams?.tickers ? all[3].split(",").map((ticker) => ({ ticker })) : [],
  );
  const [excludeTickerString, setExcludeTickerString] = useState("");
  const [excludeTickers, setExcludeTickers] = useState([]);
  const [excludeSymbol, setExcludeSymbol] = useState("");

  const handleIncrementPrem = () => {
    const newValue = minPremium + 10000; // Increment by 1000
    setMinPremium(newValue);
  };

  const handleDecrementPrem = () => {
    const newValue = Math.max(minPremium - 10000, 35000); // Decrement by 1000
    setMinPremium(newValue);
  };

  const handleChangePrem = (event, newValue) => {
    if (typeof newValue === "number") {
      setMinPremium(newValue);
    }
  };

  useEffect(() => {
    return history.listen((location) => {
      if (history.action === "POP") {
        // Handle events
        const all = location.pathname.slice(1).split("/");
        all.shift();
        setMinPremium(Number(all[0]));
        const [minExpiry, maxExpiry] = all[1].split(",").map(Number);
        setExpiryRange([minExpiry, maxExpiry]);
        setContractMin(Number(all[2]));
        if (all[3]) {
          const mapped = all[3].split(",").map((ticker) => ({ ticker }));
          setTicker(mapped);
          setTickerString(all[3]);
        } else {
          setTicker([]);
          setTickerString("");
        }
      }
    });
  }, [history]);

  useEffect(() => {
    let basePath = "/live-options";
    if (!company) {
      // prem/minExpiry,maxExpiry/minContract/tickers/dateRange
      const premBool = Number(allParams?.prem) !== Number(debouncedPrem);
      const expBool =
        Number(allParams?.minExpiry) !== Number(debouncedExpire[0]) ||
        Number(allParams?.maxExpiry) !== Number(debouncedExpire[1]);
      const contractMinBool =
        Number(allParams?.minContract) !== Number(debouncedContractMin);
      const tickerBool = (allParams?.tickers || "") !== tickerString;

      if (premBool || expBool || contractMinBool || tickerBool) {
        history.push(
          `${basePath}/${debouncedPrem}/${debouncedExpire.join(",")}/${debouncedContractMin}${tickerBool ? `/${tickerString}` : tickerString.length ? `/${tickerString}` : ""}${allParams?.dateRange ? `/${allParams.dateRange}` : ""}`,
        );
      }
    }
    // to do add date
  }, [
    debouncedPrem,
    debouncedExpire,
    debouncedContractMin,
    tickerString,
    company,
  ]);

  const handleChangeContractMin = (event, newValue) => {
    if (typeof newValue === "number") {
      setContractMin(newValue);
    }
  };
  const [open, setOpen] = useState(false);
  const debouncedExcludeSymbol = useDebounce(excludeSymbol, 500);
  const debouncedSymbol = useDebounce(symbol, 500);
  const hide = useMemo(() => {
    return (
      company &&
      !optionsLoading &&
      minPremium === 35000 &&
      expiryRange[1] === 730 &&
      newLiveOptions.length === 0 &&
      dateRangeString === `Today's Contracts` &&
      bidAsk === "1"
    );
  }, [
    company,
    debouncedPrem,
    debouncedExpire,
    newLiveOptions,
    dateRangeString,
    bidAsk,
  ]);
  const rowClassName = useCallback(
    (params) => {
      return params?.row?.newItem ? `new-item-${themeMode}` : "";
    },
    [themeMode],
  );
  const IconAndTooltip = useCallback(
    (bid, ask, fillPrice) => {
      let text = "";
      let tooltip = "";
      let Icon = null;

      // Adjust conditions according to the fillLookup logic
      if (ask < fillPrice) {
        // Above Ask
        text = "AA";
        tooltip = "Above Ask, Price Filled above Ask";
        Icon = AAi;
      } else if (ask === fillPrice) {
        // At Ask
        text = "A";
        tooltip = "At Ask, Price Filled at Ask";
        Icon = LBi; // New icon for "At Ask"
      } else if (ask - fillPrice < fillPrice - bid) {
        // Leaning Ask
        text = "LA";
        tooltip = "Leaning Ask, Price Filled leaning Ask";
        Icon = LAi;
      } else if (bid > fillPrice) {
        // Below Bid
        text = "BB";
        tooltip = "Below Bid, Price Filled below Bid";
        Icon = BBi;
      } else if (bid === fillPrice) {
        // At Bid
        text = "AB";
        tooltip = "At Bid, Price Filled at Bid";
        Icon = BBi; // New icon for "At Bid"
      } else if (ask - fillPrice > fillPrice - bid) {
        // Leaning Bid
        text = "LB";
        tooltip = "Leaning Bid, Price Filled leaning Bid";
        Icon = LBi;
      } else if (ask - fillPrice === fillPrice - bid) {
        // In Between
        // text = "IB";
        // tooltip = "In Between, Price Filled exactly between Bid and Ask";
        // Icon = IBi;
      }

      // Render the tooltip and icon if applicable
      return (
        <Tooltip
          classes={{
            tooltip: classes[text],
            arrow: classes[`${text}i`],
          }}
          className="align-center d-flex"
          title={tooltip}
          placement="top"
          arrow
        >
          <span
            style={{ position: "relative", left: isMobile ? "-6px" : "" }}
            className={`bid-ask-base ${text}`}
          >
            {text}
            {Icon && <img src={Icon} className="BBi" alt={text} />}
          </span>
        </Tooltip>
      );
    },
    [isMobile],
  );

  const columns = useMemo(() => {
    let base = [
      {
        field: "time",
        headerName: "Time",
        renderHeader: () => <span className="table-headers2">Time</span>,
        renderCell: (params) => (
          <span
            className={clsx(
              classes.text,
              `time  ${!isMobile && "large-table"}`,
            )}
          >
            {moment(Number(params.value) * 1000).isSame(moment(), "Day")
              ? moment
                  .tz(Number(params.value) * 1000, "America/New_York")
                  .format("hh:mm:ss A")
              : moment
                  .tz(Number(params.value) * 1000, "America/New_York")
                  .format("MM/DD hh:mm:ss A")}
          </span>
        ),
        flex: 2.6,
        sortComparator: (v1, v2) => Number(v1) - Number(v2),
      },
      {
        field: "ticker",
        headerName: "Ticker",
        renderHeader: () => <span className="table-headers">Ticker</span>,
        renderCell: (params) => (
          <span
            className="ticker"
            style={{ position: "relative", left: "-9px" }}
            onClick={() => {
              setSymbolRedux({
                type: "",
                value: params.value,
              });
            }}
          >
            <Link
              to={`/company/${params.value}`}
              className={clsx(classes.text, "ticker")}
            >
              <span
                // aria-id="option-ticker"
                className={clsx(classes.text, "ticker")}
              >
                {params.value}
              </span>
            </Link>
          </span>
        ),
        flex: 1,
      },
      {
        field: "exp",
        headerName: "Expiry",
        flex: 1,
        renderHeader: () => <span className="table-headers">Expiry</span>,
        renderCell: (params) => (
          <span className={clsx(classes.text, "strike")}>
            {moment.utc(params.value).format("MM/DD/YY")}
          </span>
        ),
      },
      {
        field: "strike",
        headerName: "Strike",
        renderHeader: () => <span className="table-headers">Strike</span>,
        renderCell: (params) => (
          <span className={clsx(classes.text, "strike")}>{params.value}</span>
        ),
        flex: 0.8,
        sortComparator: (v1, v2) => Number(v1) - Number(v2),
      },

      {
        field: "cp",
        headerName: "C/P",
        flex: 0.8,
        renderHeader: () => <span className="table-headers">C/P</span>,
        renderCell: (params) => (
          <span
            className={`cp ${params.value === "C" ? "green-call" : "red-put"}`}
          >
            {params.value === "C" ? "Calls" : "Puts"}
          </span>
        ),
      },
      {
        field: "spot",
        headerName: "Spot",
        flex: 0.8,
        renderHeader: () => <span className="table-headers">Spot</span>,
        renderCell: (params) => (
          <span className={classes.text} style={{ marginLeft: "3px" }}>
            {Number(params.value).toFixed(2)}
          </span>
        ),
        sortComparator: (v1, v2) => Number(v1) - Number(v2),
      },
      {
        field: "bid_price",
        headerName: "Bid",
        flex: 0.8,
        renderHeader: () => <span className="table-headers">Bid</span>,
        renderCell: (params) => (
          <span className={classes.text} style={{ marginLeft: "3px" }}>
            {Number(params.value).toFixed(2)}
          </span>
        ),
        sortComparator: (v1, v2) => Number(v1) - Number(v2),
      },
      {
        field: "contract_quantity",
        headerName: "Details",
        renderHeader: () => <span className="table-headers">Details</span>,
        renderCell: (params) => {
          return (
            <span
              style={{ position: "relative", left: "-3px" }}
              className={clsx(
                classes.text,
                `ppc ${isMobile ? "" : "d-flex align-center"}`,
              )}
            >
              {Math.round(params.row.contract_quantity) +
                " @ " +
                Number(params?.row?.price_per_contract || 0)?.toFixed(2) || ""}
              {isMobile && <br />}
              {IconAndTooltip(
                Number(params.row.bid_price),
                Number(params.row.ask_price),
                Number(params.row.price_per_contract),
              )}
            </span>
          );
        },
        flex: 2,
      },
      {
        field: "ask_price",
        headerName: "Ask",
        flex: 0.8,
        renderHeader: () => <span className="table-headers">Ask</span>,
        renderCell: (params) => (
          <span className={classes.text} style={{ marginLeft: "3px" }}>
            {Number(params.value).toFixed(2)}
          </span>
        ),
        sortComparator: (v1, v2) => Number(v1) - Number(v2),
      },
      {
        field: "type",
        headerName: "Type",
        renderHeader: () => <span className="table-headers">Type</span>,
        renderCell: (params) => (
          <span className={clsx(classes.text, "type-cell")}>
            {params.value}
          </span>
        ),
        flex: 1,
      },
      {
        field: "prem",
        headerName: "Premium",
        flex: 1.5,
        renderHeader: () => <span className="table-headers">Premium</span>,
        renderCell: (params) => (
          <span className="prem-cell">${abbreviate(params.value)}</span>
        ),
        sortComparator: (v1, v2) => Number(v1) - Number(v2),
      },
    ];
    if (!dateRangeString.includes("Today")) {
      base.splice(6, 1);
      base.splice(7, 1);
    }
    return base;
  }, [isMobile, dateRangeString, classes, bidAsk, isResponsiveHeaderCell]);
  const [sortModel, setSortModel] = useState([
    {
      field: "time",
      sort: "desc",
    },
  ]);

  const handleChangeExpire = (event, newValue) => {
    if (Array.isArray(newValue)) {
      setExpiryRange(newValue); // Ensure expiryRange is an array, e.g., [min, max]
    }
  };

  useEffect(() => {
    company
      ? listen([`options;${symbols}`, "CHAT"])
      : listen(["options", "CHAT", "TRADE_MESSAGE"]);
    return () => listen([]);
  }, [symbols, company]);
  useEffect(() => {
    return () => {
      liveFetchOptions(
        35000,
        730,
        false,
        "",
        "",
        true,
        sortModel[0],
        bidAsk,
        debouncedContractMin,
      );
    };
  }, []);
  const prevValues = useMemo(() => {
    return prevBidAsk !== bidAsk || prevRange !== dateRangeString;
  }, [prevBidAsk, bidAsk, prevRange, dateRangeString]);
  useEffect(() => {
    if (company) {
      !!symbols &&
        liveFetchOptions(
          debouncedPrem,
          debouncedExpire,
          false,
          rangeString,
          symbols,
          prevValues,
          sortModel[0],
          bidAsk,
          debouncedContractMin,
        );
    } else {
      liveFetchOptions(
        debouncedPrem,
        debouncedExpire,
        false,
        rangeString,
        tickerString,
        prevValues,
        sortModel[0],
        bidAsk,
        debouncedContractMin,
        excludeTickerString,
      );
    }
  }, [
    debouncedPrem,
    debouncedExpire,
    rangeString,
    tickerString,
    company,
    symbols,
    sortModel,
    bidAsk,
    prevValues,
    debouncedContractMin,
    excludeTickerString,
  ]);
  const getMoreRows = useCallback(() => {
    liveFetchOptions(
      debouncedPrem,
      debouncedExpire,
      true,
      rangeString,
      company ? symbols : tickerString,
      false,
      sortModel[0],
      bidAsk,
      debouncedContractMin,
      excludeTickerString,
    );
  }, [
    debouncedPrem,
    debouncedExpire,
    rangeString,
    tickerString,
    liveFetchOptions,
    company,
    symbols,
    sortModel,
    bidAsk,
    debouncedContractMin,
    excludeTickerString,
  ]);

  useEffect(() => {
    if (debouncedExcludeSymbol.length) {
      searchAllSymbols(debouncedExcludeSymbol, true);
    }
  }, [debouncedExcludeSymbol]);
  useEffect(() => {
    if (debouncedSymbol.length) {
      searchAllSymbols(debouncedSymbol);
    }
  }, [debouncedSymbol]);
  useEffect(() => {
    if (
      moment().format("YYYY-MM-DD") ===
      moment(dateRange.startDate).format("YYYY-MM-DD")
    ) {
      setRangeString("");
    } else {
      setRangeString(
        `${moment
          .tz(dateRange.startDate, "America/New_York")
          .format("YYYY-MM-DD")},${moment
          .tz(dateRange.endDate, "America/New_York")
          .format("YYYY-MM-DD")}`,
      );
    }
  }, [JSON.stringify(dateRange)]);

  useEffect(() => {
    if (tickerString.length) {
      setExcludeTickerString("");
      setExcludeTickers([]);
    }
  }, [tickerString]);

  useEffect(() => {
    if (!!firstRender && !company) {
      if (!allParams?.tickers) {
        const tickers = defaultTickersSettings?.length
          ? defaultTickersSettings?.split(",")?.map((ticker) => ({ ticker }))
          : [];
        setTicker(tickers);
      }
    }
  }, [firstRender, company]);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShow(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <>
      {!company && (
        <Helmet>
          <title>Live Options | TradeAlgo </title>
        </Helmet>
      )}{" "}
      {hide ? null : (
        <InfiniteScroll
          dataLength={newLiveOptions.length}
          next={getMoreRows}
          hasMore={true}
          scrollableTarget="scrollable-target"
          style={{ overflow: "none" }}
        >
          <div style={{ padding: "10px 0" }}>
            <div className="ticker-search-container">
              {!company && (
                <div className={"live-option-search-filter"}>
                  <OptionTickerSearch
                    open={open}
                    setOpen={setOpen}
                    valueLabelDisplay="on"
                    options={searchedSymbols}
                    value={symbol}
                    setSymbol={setSymbol}
                    symbol={symbol}
                    tickers={ticker}
                    loading={loading}
                    setTicker={setTicker}
                    // setFilter={setFilter}
                    // fetch={fetch}
                    setTickerString={setTickerString}
                    customMinWidth={200}
                  />
                </div>
              )}

              {!!(!tickerString.length && !company) && (
                <div className={"live-option-search-filter"}>
                  <OptionTickerSearch
                    exclude={true}
                    open={open}
                    setOpen={setOpen}
                    valueLabelDisplay="on"
                    options={excludeSymbols}
                    value={excludeSymbol}
                    setSymbol={setExcludeSymbol}
                    symbol={excludeSymbol}
                    tickers={excludeTickers}
                    loading={loading}
                    setTicker={setExcludeTickers}
                    setTickerString={setExcludeTickerString}
                    customMinWidth={200}
                  />
                </div>
              )}

              <div className="live-option-search-filter ">
                <BidAskSelect
                  themeMode={themeMode}
                  value={bidAsk}
                  setValue={setBidAsk}
                />
              </div>
            </div>

            <div className="contents">
              {company && (
                <div className={`contents-title contents-title-${themeMode}`}>
                  Contracts Summary
                </div>
              )}
              <Summary />
            </div>
            <br />

            <div
              className={`row live-options-filter-container ${show ? "rangepicker-open" : ""}`}
            >
              <div
                style={{ zIndex: show ? 2 : 1 }}
                className={"live-option-date-filter"}
              >
                <span className={"dates"}>
                  <span className={`dates-label dates-label-${themeMode}`}>
                    Contracts
                  </span>
                  <div
                    className="live-option-date-selector"
                    onClick={() => setShow(!show)}
                  >
                    <span>Date: {dateRangeString}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.5 2H6V4C3.79086 4 2 5.79086 2 8V18C2 20.2091 3.79086 22 6 22H18C20.2091 22 22 20.2091 22 18V8C22 5.79086 20.2091 4 18 4V2H16.5V4H7.5V2ZM6 5.5H18C19.2095 5.5 20.2184 6.35888 20.45 7.5H3.55001C3.78164 6.35888 4.79052 5.5 6 5.5ZM3.5 9V18C3.5 19.3807 4.61929 20.5 6 20.5H18C19.3807 20.5 20.5 19.3807 20.5 18V9H3.5Z"
                        fill="black"
                        fillOpacity="0.56"
                      />
                    </svg>
                  </div>
                  {show && (
                    <div
                      ref={wrapperRef}
                      className="live-option-date-rangepicker"
                    >
                      <RangePicker
                        style={{ gridArea: "1 / 1 / 2 / 2" }}
                        selectedRange={dateRange}
                        onRangeSelect={(r) => {
                          setDateRange(r);
                          if (
                            moment(r.endDate).diff(
                              moment(r.startDate),
                              "days",
                            ) >= 1
                          ) {
                            setTimeout(() => {
                              setShow(!show);
                            }, 500);
                          }
                        }}
                      />
                    </div>
                  )}
                </span>
              </div>

              <div
                className="live-options-slider-container"
                style={{ zIndex: show ? 1 : 2 }}
              >
                <div className="live-options-slider">
                  <CustomSlider
                    value={minPremium}
                    min={35000}
                    step={1000}
                    max={10000000}
                    valueLabelDisplay="auto"
                    valueLabelFormat={valuetext}
                    onChange={handleChangePrem}
                    aria-labelledby="non-linear-slider"
                  />
                  <div className="slider-label">
                    <div className={`min-prem-value-${themeMode}`}>
                      {abbreviate(35000)}
                    </div>
                    <Tooltip title="Click to decrement the minimum premium by 10k">
                      <IconButton
                        aria-label="delete"
                        size="medium"
                        onClick={handleDecrementPrem}
                      >
                        <RemoveIcon fontSize="inherit" color="success" />
                      </IconButton>
                    </Tooltip>
                    <div className={`min-prem-${themeMode}`}>
                      Minimum Premium
                    </div>
                    <Tooltip title="Click to increment the minimum premium by 10k">
                      <IconButton
                        aria-label="delete"
                        size="medium"
                        onClick={handleIncrementPrem}
                      >
                        <AddIcon fontSize="inherit" color="success" />
                      </IconButton>
                    </Tooltip>
                    <div className={`min-prem-value-${themeMode}`}>
                      {abbreviate(10000000)}
                    </div>
                  </div>
                </div>
                <div className="live-options-slider up-7">
                  <CustomSlider
                    value={expiryRange}
                    min={0}
                    step={1}
                    max={365 * 2}
                    valueLabelDisplay="off"
                    valueLabelFormat={valuetextExpire}
                    onChange={handleChangeExpire}
                    aria-labelledby="range-slider"
                  />
                  <div className="slider-label mt-15">
                    <div className={`exp-within-value-${themeMode}`}>1 day</div>
                    <div className={`exp-within-${themeMode}`}>
                      Expires Within
                    </div>
                    <div className={`exp-within-value-${themeMode}`}>
                      730 days
                    </div>
                  </div>
                </div>
                <div className="live-options-slider">
                  <CustomSlider
                    value={contractMin}
                    min={0}
                    step={1}
                    max={100000}
                    valueLabelFormat={valuetext}
                    onChange={handleChangeContractMin}
                    aria-labelledby="contract-min-slider"
                  />
                  <div className="slider-label">
                    <div className={`exp-within-value-${themeMode}`}>0</div>
                    <Tooltip title="Click to decrement the minimum contracts by 10">
                      <IconButton
                        aria-label="delete"
                        size="medium"
                        onClick={() => setContractMin((prev) => prev - 10)}
                      >
                        <RemoveIcon fontSize="inherit" color="success" />
                      </IconButton>
                    </Tooltip>
                    <div className={`exp-within-${themeMode}`}>
                      Minimum Contracts
                    </div>
                    <Tooltip title="Click to increment the minimum contracts by 10">
                      <IconButton
                        aria-label="add"
                        size="medium"
                        onClick={() => setContractMin((prev) => prev + 10)}
                      >
                        <AddIcon fontSize="inherit" color="success" />
                      </IconButton>
                    </Tooltip>
                    <div className={`exp-within-value-${themeMode}`}>100K</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              {optionsLoading && !newLiveOptions.length ? (
                <TableSkeleton />
              ) : (
                <TradeAlgoTable
                  sortModel={sortModel}
                  setSortModel={setSortModel}
                  rows={newLiveOptions}
                  columns={columns}
                  loading={false}
                  hasRowClassName={true}
                  rowClassName={rowClassName}
                />
              )}
            </div>
          </div>
        </InfiniteScroll>
      )}
    </>
  );
};

const stateToProps = (state) => ({
  searchedSymbols: state.searchSymbols.searchSymbols,
  excludeSymbols: state.searchSymbols.excludeSymbols,
  loading: state.searchSymbols.symbolsLoading,
  symbols: state.symbol.symbol.value,
  newLiveOptions: state.newLiveOptions.liveOptions,
  optionsLoading: state.newLiveOptions.loading,
  themeMode: state.themeMode.themeMode,
  defaultTickersSettings:
    state.userSettings.userSettings.defaultLiveOptionsTickers,
  firstRender: state.userSettings.firstRender,
});

const dispatchToProps = (dispatch) => ({
  searchAllSymbols: (symbol, exclude = false) =>
    dispatch(searchLoad(symbol, exclude)),
  listen: (channels) => dispatch(socketListen(channels)),
  setSymbolRedux: (symbol) => dispatch(symbolSetter(symbol)),
  liveFetchOptions: (
    tickers,
    prem_min,
    exp_within,
    last_time,
    date,
    clearCache,
    sortModel,
    bidAskFilter,
    contract_quantity_min,
    exclude,
  ) =>
    dispatch(
      liveOptionsLoad(
        tickers,
        prem_min,
        exp_within,
        last_time,
        date,
        clearCache,
        sortModel,
        bidAskFilter,
        contract_quantity_min,
        exclude,
      ),
    ),
});

export default connect(stateToProps, dispatchToProps)(LiveOptionsContainer);
