import { all, put, takeEvery, fork, select } from "redux-saga/effects";
import { apiURL } from "../actions/helpers";
import axios from "axios";
import moment from "moment";
import { SOCKET_MESSAGE } from "./socket";
import orderBy from "lodash/orderBy";
import clone from "lodash/clone";
import { v4 as uuidv4 } from "uuid";

// Action Types
export const LIVE_OPTIONS_LOAD = "LOF/LOAD/TOP";
export const LIVE_OPTIONS_LOAD_SUCCESS = "LOF/LOAD/TOP/SUCCESS";
export const LIVE_OPTIONS_LOAD_FAILURE = "LOF/LOAD/TOP/FAILURE";

// Action Creators
export const liveOptionsLoad = (
  prem_min = 35000,
  exp_within = 730,
  loadMore = false,
  date = "",
  tickers = "",
  clearCache = false,
  sortModel = {
    field: "time",
    sort: "desc",
  },
  bidAskFilter = [],
  contract_quantity_min = 0,
  exclude = "",
) => ({
  type: LIVE_OPTIONS_LOAD,
  tickers,
  prem_min,
  exp_within,
  loadMore,
  date,
  clearCache,
  sortModel,
  bidAskFilter,
  contract_quantity_min,
  exclude,
});
export const liveOptionsLoadSuccess = (data) => ({
  type: LIVE_OPTIONS_LOAD_SUCCESS,
  data,
});
export const liveOptionsLoadFailure = (error) => ({
  type: LIVE_OPTIONS_LOAD_FAILURE,
  error,
});
// Sagas
function* fetchLiveOptions(action) {
  try {
    // order_direction=desc&sort_column=time
    const {
      liveOptions,
      date: dateRedux,
      prem_min: prem_min_redux,
      exp_within: exp_within_redux,
      sortModel: sortModelRedux,
      contract_quantity_min: contract_quantity_min_redux,
    } = yield select((state) => state.newLiveOptions);
    const newLiveOptionsState = yield select((state) => state.newLiveOptions);
    const {
      prem_min,
      exp_within,
      loadMore,
      date,
      tickers,
      clearCache,
      sortModel,
      bidAskFilter,
      contract_quantity_min,
      exclude,
    } = action;
    yield put(
      liveOptionsLoadSuccess({
        ...newLiveOptionsState,
        clearCache,
        liveOptions,
        loading: true,
        sortModel,
        bidAskFilter,
        contract_quantity_min,
        exclude,
      }),
    );
    if (!clearCache) {
      const diffDates = dateRedux !== date;
      const diffPrem = prem_min !== prem_min_redux;
      const diffExp =
        JSON.stringify(exp_within) !== JSON.stringify(exp_within_redux);
      const diffContractMin =
        JSON.stringify(contract_quantity_min) !==
        JSON.stringify(contract_quantity_min_redux);
      const diffSortModel =
        JSON.stringify(sortModel) !== JSON.stringify(sortModelRedux);
      if (
        diffDates ||
        diffPrem ||
        diffExp ||
        diffSortModel ||
        diffContractMin
      ) {
        yield put(
          liveOptionsLoadSuccess({
            clearCache,
            liveOptions: [],
            tickers,
            prem_min,
            exp_within,
            loadMore: false,
            date,
            summary: {},
            loading: true,
            sortModel,
            bidAskFilter,
            contract_quantity_min,
            exclude,
          }),
        );
      }
      const baFilter = bidAskFilter.length ? bidAskFilter.join(",") : "";
      let uri = `${apiURL}/darkpool/options?limit=101&prem_min=${prem_min}&exp_within=${exp_within}&order_direction=${sortModel.sort}&sort_column=${sortModel.field}&bid_ask=${baFilter}&contract_quantity_min=${contract_quantity_min}`;

      let snapshotUri = `${apiURL}/darkpool/snapshot?prem_min=${prem_min}&exp_within=${exp_within}&contract_quantity_min=${contract_quantity_min}`;

      if (tickers.length) {
        uri += `&ticker=${tickers}`;
        snapshotUri += `&ticker=${tickers}`;
      }

      if (exclude.length) {
        uri += `&exclude=${exclude}`;
        snapshotUri += `&exclude=${exclude}`;
      }
      if (loadMore) {
        if (!!liveOptions[liveOptions.length - 1]?.time || false) {
          const last_time = [...liveOptions].sort(
            (a, b) => Number(b.time) - Number(a.time),
          )[liveOptions.length - 1].time;
          uri += `&last_time=${last_time}`;
        }
      }
      if (date.length) {
        uri += `&date=${date}`;
        snapshotUri += `&date=${date}`;
      }
      const response = yield axios.get(uri, {
        withCredentials: true,
      });
      let data = [...liveOptions.concat(response.data)];
      if (date.length) {
        const [startDate, endDate] = date.split(",");
        // extra check to ensure data validity
        data = data.filter(
          (item) =>
            moment(Number(item.time) * 1000).isSameOrAfter(startDate, "day") &&
            moment(Number(item.time) * 1000).isSameOrBefore(endDate, "day"),
        );
      }
      data = [...new Map(data.map((item) => [item["id"], item])).values()];
      const snapshotResponse = yield axios.get(snapshotUri, {
        withCredentials: true,
      });
      yield put(
        liveOptionsLoadSuccess({
          clearCache,
          liveOptions: data,
          tickers,
          prem_min,
          exp_within,
          loadMore: false,
          date,
          summary: snapshotResponse.data,
          loading: false,
          sortModel,
          bidAskFilter,
          contract_quantity_min,
          exclude,
        }),
      );
    } else {
      yield put(
        liveOptionsLoadSuccess({
          clearCache,
          liveOptions: [],
          tickers,
          prem_min,
          exp_within,
          loadMore: false,
          date,
          summary: {},
          loading: false,
          sortModel,
          bidAskFilter,
          contract_quantity_min,
          exclude,
        }),
      );
    }
  } catch (error) {
    console.log(error, "ERROR IN CATCH\n\n");
    yield put(liveOptionsLoadFailure(error));
  }
}

function* listenLiveOptionsLoad() {
  yield takeEvery(LIVE_OPTIONS_LOAD, fetchLiveOptions);
}

/**
 * Ensure that a given item is in array format
 * @param {string | array} item
 * @returns {Array.<string>}
 */
function ensureArrayFormat(item) {
  if (typeof item === "string" && item.includes(",")) {
    return item.split(",");
  }
  if (typeof item === "string" && item.length) {
    return [item];
  }
  return item;
}

// Root Saga
export function* saga() {
  yield all([fork(listenLiveOptionsLoad)]);
}

const INIT_STATE = {
  liveOptions: [],
  tickers: "",
  exclude: "",
  prem_min: 35000,
  exp_within: 730,
  contract_quantity_min: 0,
  loadMore: false,
  date: "",
  summary: {
    put_count: 0,
    call_count: 0,
    call_flow: 0,
    put_flow: 0,
    flow_sentiment: 0.5,
  },
  loading: true,
  clearCache: false,
  sortModel: {
    field: "time",
    sort: "desc",
  },
  bidAskFilter: [],
};
// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SOCKET_MESSAGE: {
      try {
        // Only update state if the message is related to options
        if (!action.channel.includes("options")) {
          return state;
        }
        // If the user is looking at historic options, don't update the state
        const lookingAtHistoricOptions =
          state.date.length > 0 &&
          moment(state.date.split(",")[1]).isBefore(
            moment.utc().format("YYYY-MM-DD"),
          );
        if (lookingAtHistoricOptions) {
          return state;
        }
        if (state.clearCache) {
          return { ...state, liveOptions: [] };
        }
        const mergedData = state.liveOptions.concat(action.data);
        let liveOptions = [];
        let newSummary = clone(state.summary);
        for (let i = 0; i < mergedData.length; i++) {
          const option = mergedData[i];

          if (state.tickers.length && !state.tickers.includes(option.ticker)) {
            continue;
          }
          if (state.exclude.length && state.exclude.includes(option.ticker)) {
            continue;
          }
          if (state.prem_min && Number(option.prem) < state.prem_min) {
            continue;
          }
          if (state.exp_within.length) {
            let [minExp, maxExp] = state.exp_within;
            let maxExpiryDate = moment().add(maxExp, "days");
            let minExpiryDate = moment().add(minExp, "days");
            if (
              moment(option.exp).isBefore(minExpiryDate) ||
              moment(option.exp).isAfter(maxExpiryDate)
            ) {
              continue;
            }
          }
          if (state.bidAskFilter.length) {
            const { ask_price, bid_price, price_per_contract } = option;
            const fillLookup = {
              "": "",
              1: ask_price < price_per_contract, // Above Ask
              2: ask_price === price_per_contract, // At Ask
              3:
                ask_price - price_per_contract < price_per_contract - bid_price, // Lean Ask
              4: bid_price > price_per_contract, // Below Bid
              5: bid_price === price_per_contract, // At bid
              6:
                ask_price - price_per_contract > price_per_contract - bid_price, // Lean Bid
              7:
                ask_price - price_per_contract ===
                price_per_contract - bid_price, // In Between
            };
            if (!state.bidAskFilter.some((i) => fillLookup[i])) {
              continue;
            }
          }
          if (
            state.contract_quantity_min > 0 &&
            option.contract_quantity < state.contract_quantity_min
          ) {
            continue;
          }
          if (!option.id) {
            option.newItem = true;
            option.id = uuidv4();
            if (option.cp === "P") {
              newSummary.put_count++;
              newSummary.put_total_prem += parseInt(option.prem);
            } else {
              newSummary.call_count++;
              newSummary.call_total_prem += parseInt(option.prem);
            }
          }
          liveOptions.push(option);
        }
        liveOptions = orderBy(
          liveOptions,
          [state.sortModel.field],
          [state.sortModel.sort],
        );
        const putToCallRatio = newSummary.put_count / newSummary.call_count;
        newSummary.put_to_call = Math.round(putToCallRatio * 1000) / 1000;
        const optionsCount = newSummary.call_count + newSummary.put_count;
        newSummary.call_flow = newSummary.call_count / optionsCount;
        newSummary.flow_sentiment = newSummary.call_count / optionsCount;
        newSummary.put_flow = newSummary.put_count / optionsCount;

        return {
          ...state,
          liveOptions,
          summary: newSummary,
        };
      } catch (error) {
        console.log("error in socket message catch--");
        console.error(error);
        return state;
      }
    }
    case LIVE_OPTIONS_LOAD_SUCCESS: {
      let {
        tickers,
        prem_min,
        exp_within,
        date,
        loadMore,
        liveOptions,
        summary,
        loading,
        clearCache,
        sortModel,
        bidAskFilter,
        contract_quantity_min,
        exclude,
      } = action.data;
      tickers = ensureArrayFormat(tickers);
      exclude = ensureArrayFormat(exclude);
      let data = [];
      for (let i = 0; i < liveOptions.length; i++) {
        const option = liveOptions[i];
        if (tickers?.length && !tickers.includes(option.ticker)) {
          continue;
        }
        if (exclude?.length && exclude.includes(option.ticker)) {
          continue;
        }
        if (prem_min && Number(option.prem) < prem_min) {
          continue;
        }
        if (exp_within.length) {
          let [minExp, maxExp] = exp_within;
          let maxExpiryDate = moment().add(maxExp, "days");
          let minExpiryDate = moment().add(minExp, "days");
          if (
            moment(option.exp).isBefore(minExpiryDate) ||
            moment(option.exp).isAfter(maxExpiryDate)
          ) {
            continue;
          }
        }
        if (
          contract_quantity_min > 0 &&
          option.contract_quantity < contract_quantity_min
        ) {
          continue;
        }
        data.push(option);
      }

      return {
        ...state,
        tickers,
        prem_min,
        exp_within,
        date,
        loadMore,
        summary,
        loading,
        clearCache,
        liveOptions: clearCache ? [] : data,
        sortModel,
        bidAskFilter,
        contract_quantity_min,
        exclude,
      };
    }
    default:
      return state;
  }
};

export default reducer;
